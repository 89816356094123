import React from 'react';

import image1 from '../images/img1.JPG';
import image2 from '../images/img2.jpg';
import image3 from '../images/img3.jpg';

export function HomePage() {
  return (
    <div>
      <main>
        <h1 className='titleHeader'>
          Ram ram!
        </h1>
        <p className='homeParagraph'>
          My name is Cinoma and I'm the most amazing girlfriend in the whole world!
        </p>
        <p className='homeParagraph'>
          This template website was made by my loving Jaanu! <span className='heart'>❤</span>
        </p>
        <div className='imagesContainer'>
          <img className='homeImage' src={image1} alt='Image 1'></img>
          <img className='homeImage' src={image2} alt='Image 2'></img>
          <img className='homeImage' src={image3} alt='Image 3'></img>
        </div>
      </main>
    </div>
  );
}
