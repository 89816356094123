import './App.css';
import React, { useState } from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { Header } from './Components/Header'
import { Navbar } from './Components/Navbar'
import { HomePage } from './Components/HomePage'
import { Spacer } from './Components/Spacer'

function App() {
  return (
    <Switch>
      <Route exact path='/'>
        <div className='app'>
          <Header/>
          <Navbar/>
          <Spacer/>
          <HomePage/>
        </div>
      </Route>
      <Route path='/resume'>
        <div className='app'>
          <Header/>
          <Navbar/>
          {/* <ResumePage/> */}
        </div>
      </Route>
      <Route path='/contact'>
        <div className='app'>
          <Header/>
          <Navbar/>
          {/* <ContactPage/> */}
        </div>
      </Route>
    </Switch>
  );
}

export default App;
