import React from 'react';

import cinomaImage from '../images/bon.jpg';

export function Header() {
  return (
    <div>
      <header className='menu'>
          <div className='content'>
            <img src={cinomaImage} alt='BON!'/>
            <h1>Cinoma's Website</h1>
          </div>
        </header>
    </div>
  );
}
